var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    [
      _c("v-expansion-panel-header", { staticClass: "pl-2 pr-4 mx-0" }, [
        _c(
          "section",
          { staticClass: "d-flex justify-space-between align-center border" },
          [
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c("v-icon", { staticClass: "section-handle" }, [
                  _vm._v(" " + _vm._s(_vm.mdiDragVertical) + " "),
                ]),
                _c("validation-provider", {
                  attrs: { name: "Workflow Name", rules: "required" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors, valid }) {
                        return [
                          _c("v-text-field", {
                            staticClass: "py-0 mt-0 mb-n2",
                            attrs: {
                              label: "Workflow Name",
                              "hide-details": "auto",
                              "error-messages": errors,
                              success: valid,
                              color: "primary",
                              name: "name",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "space",
                                    32,
                                    $event.key,
                                    [" ", "Spacebar"]
                                  )
                                )
                                  return null
                                $event.preventDefault()
                              },
                              input: _vm.onChange,
                            },
                            model: {
                              value: _vm.workflowSettingBeingEdited.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.workflowSettingBeingEdited,
                                  "name",
                                  $$v
                                )
                              },
                              expression: "workflowSettingBeingEdited.name",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c("v-switch", {
                  staticClass: "py-0 my-0",
                  attrs: { label: "Enabled", "hide-details": "" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                    change: _vm.onChange,
                  },
                  model: {
                    value: _vm.workflowSettingBeingEdited.enabled,
                    callback: function ($$v) {
                      _vm.$set(_vm.workflowSettingBeingEdited, "enabled", $$v)
                    },
                    expression: "workflowSettingBeingEdited.enabled",
                  },
                }),
                _c(
                  "v-menu",
                  {
                    attrs: { "offset-y": "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  { attrs: { icon: "" } },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("v-icon", [
                                  _vm._v(
                                    " " + _vm._s(_vm.mdiDotsVertical) + " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "v-list",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-list-item",
                          {
                            staticClass: "pa-0 ma-0",
                            on: {
                              click: function ($event) {
                                return _vm.$emit("delete-workflow")
                              },
                            },
                          },
                          [
                            _c(
                              "v-btn",
                              { attrs: { text: "", color: "primary" } },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { color: "primary" },
                                  },
                                  [_vm._v(_vm._s(_vm.mdiDelete))]
                                ),
                                _vm._v(" Delete "),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "v-expansion-panel-content",
        [
          _c("div", { staticClass: "pb-3 font-weight-medium" }, [
            _vm._v(
              " When a ticket is received that matches the following conditions: "
            ),
          ]),
          _c("section", { staticClass: "d-flex flex-wrap gap pb-3" }, [
            _c(
              "div",
              [
                _c("div", { staticClass: "caption" }, [_vm._v("Priorities")]),
                _c("validation-provider", {
                  attrs: { name: "Ticket Priority", rules: "checkboxRequired" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors, valid }) {
                        return [
                          _c(
                            "v-menu",
                            {
                              attrs: { "offset-y": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-chip",
                                          _vm._g(
                                            _vm._b({}, "v-chip", attrs, false),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getPrioritiesChoices()
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "v-avatar",
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "ml-3" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.mdiMenuDown
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-0 mx-0 overflow-y-auto",
                                  attrs: { "max-height": "90vh" },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "py-2 px-3" },
                                    [
                                      _c("CheckboxGroup", {
                                        attrs: { errors: errors, valid: valid },
                                        on: { input: _vm.onChange },
                                        model: {
                                          value: _vm.ticketPrioritySettings,
                                          callback: function ($$v) {
                                            _vm.ticketPrioritySettings = $$v
                                          },
                                          expression: "ticketPrioritySettings",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "caption red--text" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("div", { staticClass: "caption" }, [_vm._v("Ticket Types")]),
                _c("validation-provider", {
                  attrs: { name: "Ticket Type", rules: "checkboxRequired" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors, valid }) {
                        return [
                          _c(
                            "v-menu",
                            {
                              attrs: { "offset-y": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-chip",
                                          _vm._g(
                                            _vm._b({}, "v-chip", attrs, false),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.getTypesChoices()) +
                                                " "
                                            ),
                                            _c(
                                              "v-avatar",
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "ml-3" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.mdiMenuDown
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-0 mx-0 overflow-y-auto",
                                  attrs: { "max-height": "90vh" },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "py-2 px-3" },
                                    [
                                      _c("CheckboxGroup", {
                                        attrs: { errors: errors, valid: valid },
                                        on: { input: _vm.onChange },
                                        model: {
                                          value: _vm.ticketTypeSettings,
                                          callback: function ($$v) {
                                            _vm.ticketTypeSettings = $$v
                                          },
                                          expression: "ticketTypeSettings",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "caption red--text" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("div", { staticClass: "caption" }, [_vm._v("Days")]),
                _c("validation-provider", {
                  attrs: { name: "Days", rules: "daysSettingsRequired" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ errors }) {
                        return [
                          _c(
                            "v-menu",
                            {
                              attrs: { "offset-y": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-chip",
                                          _vm._g(
                                            _vm._b({}, "v-chip", attrs, false),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.getDaysChoices()) +
                                                " "
                                            ),
                                            _c(
                                              "v-avatar",
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "ml-3" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.mdiMenuDown
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "v-card",
                                { staticClass: "pa-0 mx-0" },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pa-2" },
                                    [
                                      _c("DaysSelector", {
                                        on: { input: _vm.onChange },
                                        model: {
                                          value: _vm.daysSettings,
                                          callback: function ($$v) {
                                            _vm.daysSettings = $$v
                                          },
                                          expression: "daysSettings",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "caption red--text" }, [
                            _vm._v(_vm._s(errors[0])),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("div", { staticClass: "caption" }, [_vm._v("Hours")]),
                _c(
                  "v-menu",
                  {
                    attrs: { "offset-y": "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-chip",
                              _vm._g(_vm._b({}, "v-chip", attrs, false), on),
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.getSelectHoursChoice()) + " "
                                ),
                                _c(
                                  "v-avatar",
                                  [
                                    _c("v-icon", { staticClass: "ml-3" }, [
                                      _vm._v(
                                        " " + _vm._s(_vm.mdiMenuDown) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "v-card",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-card-text",
                          {
                            staticClass: "pa-3 ma-0",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          [
                            _c("v-switch", {
                              staticClass: "pa-0 ma-0",
                              attrs: { label: "All Hours", "hide-details": "" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                                change: _vm.onChange,
                                input: _vm.onChange,
                              },
                              model: {
                                value:
                                  _vm.workflowSettingBeingEdited.filter.hours
                                    .all_hours,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.workflowSettingBeingEdited.filter.hours,
                                    "all_hours",
                                    $$v
                                  )
                                },
                                expression:
                                  "workflowSettingBeingEdited.filter.hours.all_hours",
                              },
                            }),
                            !_vm.workflowSettingBeingEdited.filter.hours
                              .all_hours
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "caption font-weight-medium pt-2 black--text",
                                    },
                                    [_vm._v(" From ")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex gap" },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "From Hour",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors, valid }) {
                                                return [
                                                  _c("v-select", {
                                                    staticClass:
                                                      "hour-drop-down",
                                                    attrs: {
                                                      label: "Hours",
                                                      color: "primary",
                                                      "error-messages": errors,
                                                      success: valid,
                                                      "hide-details": "",
                                                      "item-text": "label",
                                                      "item-value": "value",
                                                      items: _vm.hoursChoices,
                                                    },
                                                    model: {
                                                      value: _vm.from.hour,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.from,
                                                          "hour",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "from.hour",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3359701444
                                        ),
                                      }),
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "From Minute",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors, valid }) {
                                                return [
                                                  _c("v-select", {
                                                    staticClass:
                                                      "hour-drop-down",
                                                    attrs: {
                                                      label: "Minutes",
                                                      color: "primary",
                                                      "error-messages": errors,
                                                      success: valid,
                                                      "hide-details": "",
                                                      "item-text": "label",
                                                      "item-value": "value",
                                                      items: _vm.minutesChoices,
                                                    },
                                                    model: {
                                                      value: _vm.from.minute,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.from,
                                                          "minute",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "from.minute",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          913885386
                                        ),
                                      }),
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "From Period",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors, valid }) {
                                                return [
                                                  _c("v-select", {
                                                    staticClass:
                                                      "hour-drop-down",
                                                    attrs: {
                                                      label: "Period",
                                                      color: "primary",
                                                      "error-messages": errors,
                                                      success: valid,
                                                      "hide-details": "",
                                                      "item-text": "label",
                                                      "item-value": "value",
                                                      items: _vm.periodChoices,
                                                    },
                                                    model: {
                                                      value: _vm.from.period,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.from,
                                                          "period",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "from.period",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          582564833
                                        ),
                                      }),
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "From Time Zone",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors, valid }) {
                                                return [
                                                  _c("v-select", {
                                                    staticClass:
                                                      "hour-drop-down",
                                                    attrs: {
                                                      label: "Time Zone",
                                                      color: "primary",
                                                      "error-messages": errors,
                                                      success: valid,
                                                      "hide-details": "",
                                                      "item-text": "label",
                                                      "item-value": "value",
                                                      items:
                                                        _vm.timeZoneChoices,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        _vm.to.timeZone =
                                                          _vm.from.timeZone
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.from.timeZone,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.from,
                                                          "timeZone",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "from.timeZone",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3061191999
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "caption font-weight-medium pt-2 black--text",
                                    },
                                    [_vm._v(" To ")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex gap" },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "To Hour",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors, valid }) {
                                                return [
                                                  _c("v-select", {
                                                    staticClass:
                                                      "hour-drop-down",
                                                    attrs: {
                                                      label: "Hours",
                                                      color: "primary",
                                                      "error-messages": errors,
                                                      success: valid,
                                                      "hide-details": "",
                                                      "item-text": "label",
                                                      "item-value": "value",
                                                      items: _vm.hoursChoices,
                                                    },
                                                    model: {
                                                      value: _vm.to.hour,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.to,
                                                          "hour",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "to.hour",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1918985
                                        ),
                                      }),
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "To Minute",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors, valid }) {
                                                return [
                                                  _c("v-select", {
                                                    staticClass:
                                                      "hour-drop-down",
                                                    attrs: {
                                                      label: "Minutes",
                                                      color: "primary",
                                                      "error-messages": errors,
                                                      success: valid,
                                                      "hide-details": "",
                                                      "item-text": "label",
                                                      "item-value": "value",
                                                      items: _vm.minutesChoices,
                                                    },
                                                    model: {
                                                      value: _vm.to.minute,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.to,
                                                          "minute",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "to.minute",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1309486727
                                        ),
                                      }),
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "To Period",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors, valid }) {
                                                return [
                                                  _c("v-select", {
                                                    staticClass:
                                                      "hour-drop-down",
                                                    attrs: {
                                                      label: "Period",
                                                      color: "primary",
                                                      "error-messages": errors,
                                                      success: valid,
                                                      "hide-details": "",
                                                      "item-text": "label",
                                                      "item-value": "value",
                                                      items: _vm.periodChoices,
                                                    },
                                                    model: {
                                                      value: _vm.to.period,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.to,
                                                          "period",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "to.period",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3308954732
                                        ),
                                      }),
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "To Time Zone",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors, valid }) {
                                                return [
                                                  _c("v-select", {
                                                    staticClass:
                                                      "hour-drop-down",
                                                    attrs: {
                                                      label: "Time Zone",
                                                      color: "primary",
                                                      "error-messages": errors,
                                                      success: valid,
                                                      "hide-details": "",
                                                      "item-text": "label",
                                                      "item-value": "value",
                                                      items:
                                                        _vm.timeZoneChoices,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        _vm.from.timeZone =
                                                          _vm.to.timeZone
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.to.timeZone,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.to,
                                                          "timeZone",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "to.timeZone",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1999631186
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("div", { staticClass: "caption" }, [
                  _vm._v("Assignment Area"),
                ]),
                _c(
                  "v-menu",
                  {
                    attrs: { "offset-y": "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-chip",
                              _vm._g(_vm._b({}, "v-chip", attrs, false), on),
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getSelectedLocateRequestAccountRegionChoiceName(
                                        _vm.workflowSettingBeingEdited.filter
                                          .locate_request_account_region_id
                                      )
                                    ) +
                                    " "
                                ),
                                _c(
                                  "v-avatar",
                                  [
                                    _c("v-icon", { staticClass: "ml-3" }, [
                                      _vm._v(
                                        " " + _vm._s(_vm.mdiMenuDown) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "v-card",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-card-text",
                          { staticClass: "pa-0 ma-0" },
                          [
                            _c(
                              "v-list",
                              {
                                staticClass: "pa-0 ma-0",
                                attrs: { dense: "" },
                              },
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    staticClass: "pa-1 ma-0",
                                    on: {
                                      click: function ($event) {
                                        _vm.workflowSettingBeingEdited.filter.locate_request_account_region_id =
                                          null
                                        _vm.onChange()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-content",
                                      { staticClass: "px-3 ma-0 body-1" },
                                      [_vm._v(" Not Specified ")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._l(
                                  _vm.locateRequestAccountRegionChoices,
                                  function (c) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: c.value,
                                        staticClass: "pa-1 ma-0",
                                        on: {
                                          click: function ($event) {
                                            _vm.populateAssignedTo(c.value)
                                            _vm.onChange()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          { staticClass: "px-3 ma-0 body-1" },
                                          [_vm._v(" " + _vm._s(c.label) + " ")]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("validation-provider", {
            attrs: { rules: "oneActionRequired" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("div", { staticClass: "font-weight-medium pt-2" }, [
                      _vm._v("Do the following:"),
                    ]),
                    _c("section", { staticClass: "red--text" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                    _c("DoTheFollowingForm", {
                      attrs: { users: _vm.users },
                      on: { input: _vm.onChange },
                      model: {
                        value: _vm.workflowSettingBeingEdited,
                        callback: function ($$v) {
                          _vm.workflowSettingBeingEdited = $$v
                        },
                        expression: "workflowSettingBeingEdited",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }