<template>
  <v-card class="m-2">
    <validation-observer
      ref="edit811AccountForm"
      v-slot="{ valid: isFormValid }"
    >
      <form @submit.prevent="submit">
        <v-toolbar dark color="primary" class="elevation-0">
          <v-toolbar-title>
            Edit 811 Code - {{ selected811Account.label }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('edit-811-account-form-close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text
          class="px-8 py-2 overflow-y-auto"
          :style="{
            height: $vuetify.breakpoint.xsOnly ? `${contentHeight}px` : 'auto',
            'overflow-y': 'auto',
            'max-height': $vuetify.breakpoint.xsOnly ? undefined : '60vh',
          }"
        >
          <v-row class="ma-0 pa-0">
            <v-col cols="12" class="py-2 my-0">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Call Center"
                rules="required"
              >
                <v-autocomplete
                  label="Select One Call Center"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="callCenter"
                  :items="locateRequestProvidersChoices"
                  item-text="label"
                  item-value="value"
                  v-model="account.locate_request_provider_id"
                  @change="get811Accounts()"
                  :disabled="!canManage811Codes"
                >
                </v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col
              cols="6"
              v-if="account.locate_request_provider_id"
              class="py-2 my-0"
            >
              <validation-provider
                v-slot="{ errors, valid }"
                name="811 Code"
                rules="required"
              >
                <v-text-field
                  v-model="account.name"
                  label="811 Code"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="name"
                  :disabled="!canManage811Codes"
                />
              </validation-provider>
            </v-col>

            <v-col
              cols="6"
              v-if="account.locate_request_provider_id"
              class="py-2 my-0"
            >
              <validation-provider
                v-slot="{ errors, valid }"
                name="Label"
                rules="required"
              >
                <v-text-field
                  v-model="account.label"
                  label="Label"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="name"
                  :disabled="!canManage811Codes"
                />
              </validation-provider>
            </v-col>

            <v-col
              cols="12"
              class="py-0 my-0"
              v-if="
                ['ARK811', 'DIGHOTLINE', 'Georgia811', 'Texas811'].includes(
                  selectedCallCenterCode
                )
              "
            >
              <validation-provider
                v-slot="{ errors, valid }"
                name="Facility"
                rules="required"
              >
                <v-autocomplete
                  label="Facility"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="facility"
                  :items="facilitiesChoices"
                  item-text="label"
                  item-value="value"
                  multiple
                  v-model="account.facilities"
                  :disabled="!canManage811Codes"
                />
              </validation-provider>
            </v-col>

            <v-col
              cols="12"
              class="py-2 my-0"
              v-if="['VA811', 'OHIO811'].includes(selectedCallCenterCode)"
            >
              <validation-provider v-slot="{ errors, valid }" name="Show Code">
                <v-text-field
                  v-model="account.show_code"
                  label="Show Code"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="name"
                  :disabled="!canManage811Codes"
                />
              </validation-provider>
            </v-col>

            <v-col cols="12" class="d-flex align-center">
              <v-switch
                class="py-2 my-0"
                label="Auto-Close Cancel Tickets"
                v-model="account.auto_cancel_related"
                hide-details
                :disabled="!canManage811Codes"
              >
              </v-switch>

              <v-btn fab small icon @click="showAutoCancelTicketsDialog = true">
                <v-icon color="primary"> {{ mdiInformation }} </v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" class="d-flex align-center py-2 my-0">
              <v-switch
                class="py-2 my-0"
                label="Enable Positive Response UtiliBot"
                v-model="account.send_positive_response"
                hide-details
                :disabled="!canManage811Codes"
              >
              </v-switch>

              <v-btn fab small icon @click="showPositiveResponseDialog = true">
                <v-icon color="primary"> {{ mdiInformation }} </v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12">
              <v-switch
                class="py-2 my-0"
                label="Duplicate Tickets"
                v-model="enableDuplicateTickets"
                hide-details
                :disabled="!canManage811Codes"
              >
              </v-switch>

              <validation-provider
                v-slot="{ errors, valid }"
                name="Child Accounts"
                :rules="{ required: enableDuplicateTickets }"
                v-if="enableDuplicateTickets"
              >
                <v-autocomplete
                  multiple
                  label="Duplicate Tickets to these Accounts"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="childAccount"
                  :items="locateRequestProviderAccountsChoices"
                  item-text="label"
                  item-value="value"
                  v-model="childLocateRequestProviderAccountIds"
                  :disabled="!canManage811Codes"
                >
                </v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col
              cols="12"
              v-if="
                account.send_positive_response &&
                ['CO811', 'NC811'].includes(selectedCallCenterCode)
              "
              class="py-2 my-0"
            >
              <validation-provider
                v-slot="{ errors, valid }"
                name="Positive Response Token"
                :rules="{
                  required:
                    account.send_positive_response &&
                    ['CO811', 'NC811'].includes(selectedCallCenterCode),
                }"
              >
                <v-text-field
                  v-model="account.positive_response_token"
                  label="Positive Response Token"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="name"
                  :disabled="!canManage811Codes"
                />
              </validation-provider>
            </v-col>

            <v-col
              cols="12"
              v-if="
                account.send_positive_response &&
                [
                  'VA811',
                  'MISSDIGEM',
                  'MISSDIGPEL',
                  'WY811',
                  'DIGHOTLINE',
                  'MISSUTILDE',
                  'USAN',
                  'DIGLINE',
                ].includes(selectedCallCenterCode)
              "
              class="py-2 my-0"
            >
              <validation-provider
                v-slot="{ errors, valid }"
                name="Positive Response Username"
                :rules="{
                  required:
                    account.send_positive_response &&
                    [
                      'VA811',
                      'MISSDIGEM',
                      'MISSDIGPEL',
                      'WY811',
                      'DIGHOTLINE',
                      'MISSUTILDE',
                      'USAN',
                      'DIGLINE',
                    ].includes(selectedCallCenterCode),
                }"
              >
                <v-text-field
                  v-model="account.positive_response_username"
                  label="Positive Response Username"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="positiveResponseUsername"
                  :disabled="!canManage811Codes"
                />
              </validation-provider>
            </v-col>

            <v-col
              cols="12"
              v-if="
                account.send_positive_response &&
                [
                  'VA811',
                  'MISSDIGEM',
                  'MISSDIGPEL',
                  'WY811',
                  'DIGHOTLINE',
                  'MISSUTILDE',
                  'USAN',
                  'DIGLINE',
                ].includes(selectedCallCenterCode)
              "
              class="py-2 my-0"
            >
              <validation-provider
                v-slot="{ errors, valid }"
                name="Positive Response Password"
                :rules="{
                  required:
                    account.send_positive_response &&
                    [
                      'VA811',
                      'MISSDIGEM',
                      'MISSDIGPEL',
                      'WY811',
                      'DIGHOTLINE',
                      'USAN',
                      'DIGLINE',
                    ].includes(selectedCallCenterCode),
                }"
              >
                <v-text-field
                  :type="showPassword ? 'text' : 'password'"
                  v-model="account.positive_response_password"
                  label="Positive Response Password"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="positiveResponsePassword"
                  :disabled="!canManage811Codes"
                >
                  <template #append>
                    <v-btn icon @click="showPassword = !showPassword">
                      <v-icon>{{ showPassword ? mdiEyeOff : mdiEye }} </v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col
              cols="12"
              class="py-0 my-0"
              v-if="
                account.send_positive_response &&
                ['MISSDIGEM'].includes(selectedCallCenterCode)
              "
            >
              <v-switch
                v-model="provideAdditionalResponse"
                label="Provide Additional Responses"
                @change="toggleAdditionalRespondToNames"
                :disabled="!canManage811Codes"
              ></v-switch>
            </v-col>

            <v-col cols="12" class="py-0 my-0">
              <validation-provider
                v-for="(
                  additionalRespondToName, index
                ) of additionalRespondToNames"
                :key="additionalRespondToName.id"
                v-slot="{ errors, valid }"
                name="811 Code"
                :rules="{
                  required:
                    account.send_positive_response &&
                    ['MISSDIGEM'].includes(selectedCallCenterCode),
                }"
              >
                <v-text-field
                  v-model="additionalRespondToName.value"
                  label="811 Code"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="accountCode"
                >
                  <template #append>
                    <v-icon
                      color="#093637"
                      @click="addAdditionalRespondToNames"
                      v-if="
                        allAccountCodesPresent &&
                        index === additionalRespondToNames.length - 1
                      "
                      :disabled="!canManage811Codes"
                    >
                      mdi-plus
                    </v-icon>
                    <v-icon
                      color="red"
                      @click="removeAdditionalRespondToName(index)"
                      v-if="additionalRespondToNames.length > 1"
                      :disabled="!canManage811Codes"
                    >
                      mdi-minus
                    </v-icon>
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>
          </v-row>

          <AutoCancelTicketsDialog
            :showAutoCancelTicketsDialog="showAutoCancelTicketsDialog"
            @close-auto-cancel-ticket-dialog="
              showAutoCancelTicketsDialog = false
            "
          />

          <PositiveResponseDialog
            :showPositiveResponseDialog="showPositiveResponseDialog"
            @close-positive-response-dialog="showPositiveResponseDialog = false"
          />
        </v-card-text>

        <v-card-actions>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn
              color="primary"
              type="submit"
              :dark="isFormValid"
              :disabled="!isFormValid || !canManage811Codes"
            >
              Save
            </v-btn>
          </v-col>
        </v-card-actions>
      </form>
    </validation-observer>
  </v-card>
</template>

<script>
import axios from "axios";
import { mdiInformation, mdiEye, mdiEyeOff } from "@mdi/js";
import { cloneDeep } from "lodash";
import AutoCancelTicketsDialog from "@/components/accounts-811/shared/AutoCancelTicketsDialog";
import PositiveResponseDialog from "@/components/accounts-811/shared/PositiveResponseDialog";
import { v4 as uuidv4 } from "uuid";
import DIGGERS_HOTLINE_FACILITIES from "@/constants/diggersHotlineFacilities";
import ARKANSAS_FACILITIES from "@/constants/arkansasFacilities";
import GEORGIA_FACILITIES from "@/constants/georgiaFacilities";
import TEXAS_FACILITIES from "@/constants/texasFacilities";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;
const account = {
  locate_request_provider_account_id: "",
  locate_request_provider_id: "",
  name: "",
  out_of_region_action: "",
  out_of_region_email_settings: JSON.stringify({
    body: "",
    testEmail: "",
    cc: "",
    fromName: "",
    bcc: "",
    replyTo: "",
    subject: "",
  }),
  positive_response_password: "",
  positive_response_username: "",
  send_positive_response: false,
  time_zone: "",
  user_group_id: "",
  provider_code: "",
  auto_cancel_related: false,
  show_code: "",
};

export default {
  name: "Edit811AccountForm",
  props: {
    selected811Account: Object,
    locateRequestProviders: Array,
  },
  components: {
    AutoCancelTicketsDialog,
    PositiveResponseDialog,
  },
  computed: {
    facilitiesChoices() {
      if (this.selectedCallCenterCode === "DIGHOTLINE") {
        return DIGGERS_HOTLINE_FACILITIES.map((f) => {
          const { facilityType } = f;
          return { label: facilityType, value: facilityType };
        });
      } else if (this.selectedCallCenterCode === "ARK811") {
        return ARKANSAS_FACILITIES.map((f) => {
          const { facilityType } = f;
          return { label: facilityType, value: facilityType };
        });
      } else if (this.selectedCallCenterCode === "Georgia811") {
        return GEORGIA_FACILITIES.map((f) => {
          const { facilityType } = f;
          return { label: facilityType, value: facilityType };
        });
      } else if (this.selectedCallCenterCode === "Texas811") {
        return TEXAS_FACILITIES.map((f) => {
          const { facilityType } = f;
          return { label: facilityType, value: facilityType };
        });
      }
      return [];
    },
    locateRequestProviderAccountsChoices() {
      return this.locateRequestProviderAccountsForProvider
        .filter(
          (a) =>
            a.locate_request_provider_account_id !==
            this.account.locate_request_provider_account_id
        )
        .map((a) => {
          const { name: label, locate_request_provider_account_id: value } = a;
          return {
            label,
            value,
          };
        });
    },
    locateRequestProvidersChoices() {
      return this.locateRequestProviders
        .map((l) => {
          const { locate_request_provider_id: value, name: label } = l;
          return { value, label };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    selectedCallCenterCode() {
      return this.locateRequestProviders.find((l) => {
        return (
          this.account.locate_request_provider_id ===
          l.locate_request_provider_id
        );
      })?.code;
    },
    allAccountCodesPresent() {
      return this.additionalRespondToNames.every((a) => a.value);
    },
  },
  watch: {
    enableDuplicateTickets(val) {
      if (!val) {
        this.childLocateRequestProviderAccountIds = [];
      }
    },
  },
  data() {
    return {
      account,
      mdiInformation,
      mdiEye,
      mdiEyeOff,
      showAutoCancelTicketsDialog: false,
      showPositiveResponseDialog: false,
      showPassword: false,
      provideAdditionalResponse: false,
      additionalRespondToNames: [],
      enableDuplicateTickets: false,
      childLocateRequestProviderAccountIds: [],
      locateRequestProviderAccountsForProvider: [],
    };
  },
  mixins: [permissionsMixin],
  methods: {
    toggleAdditionalRespondToNames() {
      if (this.provideAdditionalResponse) {
        this.additionalRespondToNames = [
          {
            id: uuidv4(),
            value: "",
          },
        ];
      } else {
        this.additionalRespondToNames = [];
      }
    },
    addAdditionalRespondToNames() {
      const additionalRespondToName = {
        id: uuidv4(),
        value: "",
      };
      this.additionalRespondToNames.push(additionalRespondToName);
    },
    removeAdditionalRespondToName(index) {
      this.additionalRespondToNames.splice(index, 1);
    },
    async submit() {
      const { account, additionalRespondToNames } = this;
      const success = await this.$refs.edit811AccountForm.validate();
      if (!success) {
        return;
      }
      const { user_group_id: userGroupId } = JSON.parse(
        localStorage.getItem("auth")
      );
      const locateRequestProvider = this.locateRequestProviders.find(
        (l) =>
          l.locate_request_provider_id === account.locate_request_provider_id
      );
      account.user_group_id = userGroupId;
      account.provider_code = locateRequestProvider?.code;
      const { locate_request_provider_account_id: id, ...restAccount } =
        account;
      const accountPayload = {
        ...restAccount,
        user_group_id: userGroupId,
        provider_code: locateRequestProvider?.code,
        additional_respond_to_names: additionalRespondToNames.map(
          (n) => n.value
        ),
      };

      await axios.put(
        `${APIURL}/locate_request_provider_accounts/${id}`,
        accountPayload
      );
      const accountChildrenPayload = {
        locate_request_provider_accounts:
          this.locateRequestProviderAccountsChoices.map((a) => {
            const { value: locateRequestProviderAccountId } = a;
            return {
              locate_request_provider_account_id:
                locateRequestProviderAccountId,
              is_child: this.childLocateRequestProviderAccountIds.includes(
                locateRequestProviderAccountId
              ),
            };
          }),
      };
      await axios.post(
        `${APIURL}/locate_request_provider_accounts/${account.locate_request_provider_account_id}/children`,
        accountChildrenPayload
      );
      this.$emit("edit-811-account-form-submitted");
    },
    async get811Accounts() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/locate_request_provider_accounts`);
      this.locateRequestProviderAccountsForProvider = results.filter(
        (r) =>
          r.locate_request_provider_id ===
          this.account?.locate_request_provider_id
      );
    },
  },
  async beforeMount() {
    this.get811Accounts();
    this.account = cloneDeep(this.selected811Account);
    const { additional_respond_to_names: additionalRespondToNames } =
      this.account;
    if (
      Array.isArray(additionalRespondToNames) &&
      additionalRespondToNames.length > 0
    ) {
      this.provideAdditionalResponse = true;
      this.additionalRespondToNames = [
        ...additionalRespondToNames.map((value) => {
          return {
            id: uuidv4(),
            value,
          };
        }),
      ];
    }
    const {
      data: { results },
    } = await axios.get(
      `${APIURL}/locate_request_provider_accounts/${this.account.locate_request_provider_account_id}/children`
    );
    this.childLocateRequestProviderAccountIds = results.map(
      (r) => r.locate_request_provider_account_id
    );
    this.enableDuplicateTickets =
      this.childLocateRequestProviderAccountIds.length > 0;
  },
};
</script>
