<template>
  <section>
    <section class="d-flex justify-space-between align-center py-1 flex-wrap">
      <section>
        <v-checkbox
          label="Assign Ticket"
          color="primary"
          hide-details
          class="py-1 my-0"
          v-model="workflowSettingBeingEdited.actions.assign_ticket.enabled"
          v-if="
            !workflowSettingBeingEdited.filter.locate_request_account_region_id
          "
          @change="onChange"
          @input="onChange"
        ></v-checkbox>
        <div v-else>
          <v-icon
            :disabled="
              Boolean(
                workflowSettingBeingEdited.filter
                  .locate_request_account_region_id
              )
            "
          >
            {{ mdiMinusBox }}
          </v-icon>
          <span class="ml-2 v-label theme--light">Assign Ticket</span>
        </div>
        <div
          v-if="
            workflowSettingBeingEdited.filter.locate_request_account_region_id
          "
          class="caption font-weight-medium ml-8 mt-n2"
        >
          Assignment already set based on area
        </div>
      </section>

      <validation-provider
        v-slot="{ errors, valid }"
        name="Assigned To"
        rules="required"
        class="py-1"
        v-if="
          !workflowSettingBeingEdited.filter.locate_request_account_region_id &&
          workflowSettingBeingEdited.actions.assign_ticket.enabled
        "
      >
        <v-autocomplete
          label="Assigned To"
          :error-messages="errors"
          :success="valid"
          :items="assignToUserChoices"
          item-value="value"
          item-text="label"
          color="primary"
          hide-details="auto"
          class="py-0 my-0 drop-down"
          v-model="
            workflowSettingBeingEdited.actions.assign_ticket.assign_to_user_id
          "
          :disabled="
            Boolean(
              workflowSettingBeingEdited.filter.locate_request_account_region_id
            )
          "
          @change="onChange"
        ></v-autocomplete>
      </validation-provider>
    </section>
    <section class="d-flex justify-space-between align-center py-1 flex-wrap">
      <v-checkbox
        label="Send Email Notification"
        color="primary"
        hide-details
        class="py-1 my-0"
        v-model="workflowSettingBeingEdited.actions.send_email.enabled"
        @input="onChange"
        @change="onChange"
      ></v-checkbox>

      <validation-provider
        v-slot="{ errors, valid }"
        name="Send To"
        rules="required"
        class="py-1"
        v-if="workflowSettingBeingEdited.actions.send_email.enabled"
      >
        <v-autocomplete
          label="Send To"
          :error-messages="errors"
          :success="valid"
          :items="sendToUserChoicesEmail"
          item-value="value"
          item-text="label"
          color="primary"
          hide-details="auto"
          class="py-0 my-0 drop-down"
          multiple
          v-model="workflowSettingBeingEdited.actions.send_email.email_user_ids"
          @change="onChange"
        ></v-autocomplete>
      </validation-provider>
    </section>

    <SimpleTemplateEditor
      v-if="workflowSettingBeingEdited.actions.send_email.enabled"
      class="my-2"
      v-model="workflowSettingBeingEdited.actions.send_email.message"
      @input="onChange"
    />

    <section class="d-flex justify-space-between align-center flex-wrap py-1">
      <v-checkbox
        label="Send SMS Notification"
        color="primary"
        hide-details
        class="py-1 my-0"
        v-model="workflowSettingBeingEdited.actions.send_sms.enabled"
        @input="onChange"
        @change="onChange"
      ></v-checkbox>

      <validation-provider
        v-slot="{ errors, valid }"
        name="Send To"
        rules="required"
        v-if="workflowSettingBeingEdited.actions.send_sms.enabled"
      >
        <v-autocomplete
          label="Send To"
          :error-messages="errors"
          :success="valid"
          :items="sendToUserChoicesSMS"
          item-value="value"
          item-text="label"
          color="primary"
          hide-details="auto"
          class="py-0 my-0 drop-down"
          multiple
          v-model="workflowSettingBeingEdited.actions.send_sms.sms_user_ids"
          @change="onChange"
        ></v-autocomplete>
      </validation-provider>
    </section>

    <SimpleTemplateEditor
      v-if="workflowSettingBeingEdited.actions.send_sms.enabled"
      class="my-2"
      v-model="workflowSettingBeingEdited.actions.send_sms.message"
      :showToolbarButtons="false"
      isPlainText
      @input="onChange"
    />
  </section>
</template>

<script>
import { cloneDeep } from "lodash";
import SimpleTemplateEditor from "@/components/shared/SimpleTemplateEditor";
import { mdiMinusBox } from "@mdi/js";

export default {
  name: "DoTheFollowingForm",
  props: {
    value: Object,
    users: Array,
  },
  components: { SimpleTemplateEditor },
  computed: {
    sendToUserChoicesEmail() {
      if (!Array.isArray(this.users)) {
        return [];
      }
      return this.users
        .filter(
          (u) => u.is_active && !u.is_contact && u.receive_811_notifications
        )
        .map((u) => {
          const { f_name: fname, l_name: lName, user_id: userId } = u;
          return { label: `${fname} ${lName}`, value: userId };
        })
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        });
    },
    sendToUserChoicesSMS() {
      if (!Array.isArray(this.users)) {
        return [];
      }
      return this.users
        .filter(
          (u) =>
            u.is_active &&
            !u.is_contact &&
            u.receive_811_notifications &&
            u.sms_notification
        )
        .map((u) => {
          const { f_name: fname, l_name: lName, user_id: userId } = u;
          return { label: `${fname} ${lName}`, value: userId };
        })
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        });
    },
    assignToUserChoices() {
      if (!Array.isArray(this.users)) {
        return [];
      }
      return this.users
        .filter((u) => u.is_active && !u.is_contact && u.locate_requests_user)
        .map((u) => {
          const { f_name: fname, l_name: lName, user_id: userId } = u;
          return { label: `${fname} ${lName}`, value: userId };
        })
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        });
    },
  },
  data() {
    return {
      workflowSettingBeingEdited: {},
      mdiMinusBox,
    };
  },
  methods: {
    onChange() {
      this.$emit("input", cloneDeep(this.workflowSettingBeingEdited));
    },
  },
  beforeMount() {
    this.workflowSettingBeingEdited = cloneDeep(this.value);
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.workflowSettingBeingEdited = cloneDeep(this.value);
      },
    },
  },
};
</script>

<style scoped>
.drop-down {
  width: 250px;
  max-width: 250px;
}
</style>
