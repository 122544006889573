var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "template-editor" },
    [
      _vm.editor
        ? _c(
            "section",
            {
              staticClass: "d-flex align-center flex-wrap border pa-2",
              class: {
                "justify-space-between": _vm.showToolbarButtons,
                "justify-end": !_vm.showToolbarButtons,
              },
              staticStyle: { "background-color": "#f1f1f1" },
            },
            [
              _vm.showToolbarButtons
                ? _c(
                    "div",
                    { staticClass: "d-flex flex-wrap align-center py-0 my-0" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "pa-0 ma-0",
                          staticStyle: { width: "150px" },
                        },
                        [
                          _c("v-select", {
                            staticClass: "mr-2 py-0 my-0",
                            attrs: {
                              items: _vm.fontFamilyOptions,
                              "item-text": "label",
                              "item-value": "value",
                              value: _vm.fontFamilyValue,
                              "hide-details": "",
                              disabled: _vm.disabled,
                            },
                            on: { change: _vm.onFontFamilyChange },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function ({ item: { label, value } }) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "py-0 my-0",
                                          style: {
                                            "font-family": value,
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(label) + " ")]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "selection",
                                  fn: function ({ item: { label, value } }) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "py-0 my-0",
                                          style: {
                                            "font-family": value,
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(label) + " ")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3708423452
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          class: { "is-active": _vm.editor.isActive("bold") },
                          attrs: { icon: "", disabled: _vm.disabled },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().toggleBold().run()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v(_vm._s(_vm.mdiFormatBold))])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          class: { "is-active": _vm.editor.isActive("italic") },
                          attrs: { icon: "", disabled: _vm.disabled },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().toggleItalic().run()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v(_vm._s(_vm.mdiFormatItalic))])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          class: {
                            "is-active": _vm.editor.isActive("underline"),
                          },
                          attrs: { icon: "", disabled: _vm.disabled },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().toggleUnderline().run()
                            },
                          },
                        },
                        [
                          _c("v-icon", [
                            _vm._v(_vm._s(_vm.mdiFormatUnderline)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          class: {
                            "is-active": _vm.editor.isActive("bulletList"),
                          },
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.editor
                                .chain()
                                .focus()
                                .toggleBulletList()
                                .run()
                            },
                          },
                        },
                        [
                          _c("v-icon", [
                            _vm._v(_vm._s(_vm.mdiFormatListBulleted)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          class: {
                            "is-active": _vm.editor.isActive("orderedList"),
                          },
                          attrs: { icon: "", disabled: _vm.disabled },
                          on: {
                            click: function ($event) {
                              _vm.editor
                                .chain()
                                .focus()
                                .toggleOrderedList()
                                .run()
                            },
                          },
                        },
                        [
                          _c("v-icon", [
                            _vm._v(_vm._s(_vm.mdiFormatListNumbered)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-width": 200,
                            "offset-x": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            class: {
                                              "is-active":
                                                _vm.editor.isActive("link"),
                                            },
                                            attrs: {
                                              icon: "",
                                              disabled: _vm.disabled,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.getLink()
                                              },
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v(_vm._s(_vm.mdiLink)),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2782785153
                          ),
                          model: {
                            value: _vm.showLinkMenu,
                            callback: function ($$v) {
                              _vm.showLinkMenu = $$v
                            },
                            expression: "showLinkMenu",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "pt-2 pb-3 px-4" },
                                [
                                  _c("validation-provider", {
                                    attrs: { name: "Input URL" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors, valid }) {
                                            return [
                                              _c("v-text-field", {
                                                staticClass: "pt-1",
                                                attrs: {
                                                  label: "Input URL",
                                                  "hide-details": "auto",
                                                  "error-messages": errors,
                                                  success: valid,
                                                  color: "primary",
                                                  name: "name",
                                                  disabled: _vm.disabled,
                                                },
                                                model: {
                                                  value: _vm.inputURL,
                                                  callback: function ($$v) {
                                                    _vm.inputURL = $$v
                                                  },
                                                  expression: "inputURL",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2848874765
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.showLinkMenu = false
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        dark: "",
                                        color: "primary",
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showLinkMenu = false
                                          _vm.setLink(_vm.inputURL)
                                        },
                                      },
                                    },
                                    [_vm._v(" Set URL ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "pr-2",
                                    attrs: {
                                      disabled: _vm.disabled,
                                      dark: "",
                                      color: "#286054",
                                    },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v(_vm._s(_vm.mdiPlus)),
                                ]),
                                _vm._v(" Ticket Data "),
                                _c("v-icon", { staticClass: "ml-2" }, [
                                  _vm._v(_vm._s(_vm.mdiChevronDown)),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    642268458
                  ),
                },
                [
                  _c(
                    "v-list",
                    {
                      staticClass: "pa-0 ma-0 overflow-y-auto cursor-pointer",
                      attrs: { "max-height": "60vh" },
                    },
                    [
                      _c(
                        "v-list-item",
                        {
                          staticClass: "text-body-2",
                          on: {
                            click: function ($event) {
                              return _vm.insertTicketLink()
                            },
                          },
                        },
                        [_vm._v(" Link to Ticket ")]
                      ),
                      !_vm.isPlainText
                        ? _c(
                            "v-list-item",
                            {
                              staticClass: "text-body-2",
                              on: {
                                click: function ($event) {
                                  return _vm.insertField("printable_text")
                                },
                              },
                            },
                            [_vm._v(" Printable Text ")]
                          )
                        : _vm._e(),
                      _c("v-divider"),
                      _vm._l(_vm.ticketDetailsFields, function (f) {
                        return _c(
                          "div",
                          { key: f.value },
                          [
                            _c(
                              "v-list-item",
                              {
                                staticClass: "text-body-2",
                                on: {
                                  click: function ($event) {
                                    return _vm.insertField(f.value)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(f.label) + " ")]
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("editor-content", {
        staticClass: "border",
        attrs: { editor: _vm.editor },
      }),
      Array.isArray(_vm.errors)
        ? _c("p", { staticClass: "caption red--text" }, [
            _vm._v(" " + _vm._s(_vm.errors[0]) + " "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }