var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "m-2", staticStyle: { "background-color": "#f1f2f1" } },
    [
      _c("validation-observer", { ref: "emergencyNotificationSettingsForm" }, [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submit.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "v-toolbar",
              {
                ref: "toolbar",
                staticClass: "elevation-0",
                attrs: { dark: "", color: "primary" },
              },
              [
                _c("v-toolbar-title", [
                  _vm._v(
                    "Edit Notifications - " +
                      _vm._s(_vm.selected811Account.label)
                  ),
                ]),
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "", dark: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit(
                          "edit-emergency-notification-settings-form-close"
                        )
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("mdi-close")])],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-card",
              { staticClass: "m-3 elevation-0" },
              [
                _c(
                  "v-card-text",
                  {
                    style: {
                      "background-color": "#f1f2f1",
                      height: _vm.$vuetify.breakpoint.xsOnly
                        ? `${_vm.contentHeight}px`
                        : "auto",
                      "overflow-y": "auto",
                      "max-height": _vm.$vuetify.breakpoint.xsOnly
                        ? undefined
                        : "60vh",
                    },
                  },
                  [
                    _c(
                      "v-simple-table",
                      {
                        attrs: {
                          "fixed-header": "",
                          height: _vm.$vuetify.breakpoint.xsOnly
                            ? `${_vm.contentHeight * 0.9}px`
                            : "55vh",
                        },
                      },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v("Notification:"),
                            ]),
                            _c(
                              "th",
                              {
                                staticClass: "text-center",
                                attrs: { colspan: "2" },
                              },
                              [_vm._v("Emergency")]
                            ),
                            _c(
                              "th",
                              {
                                staticClass: "text-center",
                                attrs: { colspan: "2" },
                              },
                              [_vm._v("Rush")]
                            ),
                            _vm.isCo811
                              ? _c(
                                  "th",
                                  {
                                    staticClass: "text-center",
                                    attrs: { colspan: "2" },
                                  },
                                  [_vm._v("Late")]
                                )
                              : _vm._e(),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("User")]),
                            _c("th", [_vm._v("Email?")]),
                            _c("th", [_vm._v("Text?")]),
                            _c("th", [_vm._v("Email?")]),
                            _c("th", [_vm._v("Text?")]),
                            _vm.isCo811
                              ? _c("th", [_vm._v("Email?")])
                              : _vm._e(),
                            _vm.isCo811
                              ? _c("th", [_vm._v("Text?")])
                              : _vm._e(),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.notificationSettings, function (setting) {
                            return _c("tr", { key: setting.userId }, [
                              _c("td", [
                                _vm._v(
                                  _vm._s(setting.fName) +
                                    " " +
                                    _vm._s(setting.lName)
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-center" },
                                  [
                                    _c("v-checkbox", {
                                      model: {
                                        value: setting.emergencyEmail,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            setting,
                                            "emergencyEmail",
                                            $$v
                                          )
                                        },
                                        expression: "setting.emergencyEmail",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-center" },
                                  [
                                    !setting.smsNotification
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              bottom: "",
                                              "open-on-click": "",
                                              "open-on-hover": false,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-simple-checkbox",
                                                        _vm._b(
                                                          {
                                                            directives: [
                                                              {
                                                                name: "ripple",
                                                                rawName:
                                                                  "v-ripple",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "d-flex justify-center",
                                                            attrs: {
                                                              disabled: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.closeTooltips()
                                                                on.click($event)
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                setting.emergencyText,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    setting,
                                                                    "emergencyText",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "setting.emergencyText",
                                                            },
                                                          },
                                                          "v-simple-checkbox",
                                                          attrs,
                                                          false
                                                        )
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value:
                                                _vm.showEmergencyTooltips[
                                                  setting.userId
                                                ],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.showEmergencyTooltips,
                                                  setting.userId,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "showEmergencyTooltips[setting.userId]",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "caption" },
                                              [_vm._v("No Phone Number Added ")]
                                            ),
                                          ]
                                        )
                                      : _c("v-checkbox", {
                                          attrs: {
                                            disabled: !setting.smsNotification,
                                          },
                                          model: {
                                            value: setting.emergencyText,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                setting,
                                                "emergencyText",
                                                $$v
                                              )
                                            },
                                            expression: "setting.emergencyText",
                                          },
                                        }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-center" },
                                  [
                                    _c("v-checkbox", {
                                      model: {
                                        value: setting.rushEmail,
                                        callback: function ($$v) {
                                          _vm.$set(setting, "rushEmail", $$v)
                                        },
                                        expression: "setting.rushEmail",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-center" },
                                  [
                                    !setting.smsNotification
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              bottom: "",
                                              "open-on-click": "",
                                              "open-on-hover": false,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-simple-checkbox",
                                                        _vm._b(
                                                          {
                                                            directives: [
                                                              {
                                                                name: "ripple",
                                                                rawName:
                                                                  "v-ripple",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "d-flex justify-center",
                                                            attrs: {
                                                              disabled: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.closeTooltips()
                                                                on.click($event)
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                setting.rushText,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    setting,
                                                                    "rushText",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "setting.rushText",
                                                            },
                                                          },
                                                          "v-simple-checkbox",
                                                          attrs,
                                                          false
                                                        )
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value:
                                                _vm.showRushTooltips[
                                                  setting.userId
                                                ],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.showRushTooltips,
                                                  setting.userId,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "showRushTooltips[setting.userId]",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "caption" },
                                              [_vm._v("No Phone Number Added ")]
                                            ),
                                          ]
                                        )
                                      : _c("v-checkbox", {
                                          attrs: {
                                            disabled: !setting.smsNotification,
                                          },
                                          model: {
                                            value: setting.rushText,
                                            callback: function ($$v) {
                                              _vm.$set(setting, "rushText", $$v)
                                            },
                                            expression: "setting.rushText",
                                          },
                                        }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm.isCo811
                                ? _c("td", [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex justify-center" },
                                      [
                                        _c("v-checkbox", {
                                          model: {
                                            value: setting.lateEmail,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                setting,
                                                "lateEmail",
                                                $$v
                                              )
                                            },
                                            expression: "setting.lateEmail",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.isCo811
                                ? _c("td", [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex justify-center" },
                                      [
                                        !setting.smsNotification
                                          ? _c(
                                              "v-tooltip",
                                              {
                                                attrs: {
                                                  bottom: "",
                                                  "open-on-click": "",
                                                  "open-on-hover": false,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "v-simple-checkbox",
                                                            _vm._b(
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "ripple",
                                                                    rawName:
                                                                      "v-ripple",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "d-flex justify-center",
                                                                attrs: {
                                                                  disabled: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.closeTooltips()
                                                                      on.click(
                                                                        $event
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    setting.lateText,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        setting,
                                                                        "lateText",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "setting.lateText",
                                                                },
                                                              },
                                                              "v-simple-checkbox",
                                                              attrs,
                                                              false
                                                            )
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value:
                                                    _vm.showLateTooltips[
                                                      setting.userId
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.showLateTooltips,
                                                      setting.userId,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "showLateTooltips[setting.userId]",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "caption" },
                                                  [
                                                    _vm._v(
                                                      " No Phone Number Added "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _c("v-checkbox", {
                                              model: {
                                                value: setting.lateText,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    setting,
                                                    "lateText",
                                                    $$v
                                                  )
                                                },
                                                expression: "setting.lateText",
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          }),
                          0
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "v-card-actions",
        { ref: "cardAction", staticClass: "d-flex justify-end pa-5" },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "primary", dark: "" },
              on: { click: _vm.saveNotificationSettings },
            },
            [_vm._v(" Save Notifications ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }